import { TRACK_EVENTS } from "core/consts";
import { TrackEventFn, ValueOf } from "core/types";
import { onPremHttpRequest } from ".";

export type PatientDocumentType = ValueOf<typeof PATIENT_DOCUMENTS>;

export const PATIENT_DOCUMENTS = {
  DOCUMENTATION: 1,
  DISCHARGE: 2,
  TRANSITIONAL_CARE: 3,
} as const;

const documentNames = {
  [PATIENT_DOCUMENTS.DOCUMENTATION]: "EntlassDoku",
  [PATIENT_DOCUMENTS.DISCHARGE]: "EntlassZusm",
  [PATIENT_DOCUMENTS.TRANSITIONAL_CARE]: "UebergPflege",
} as const;

const documentDescriptions = {
  [PATIENT_DOCUMENTS.DOCUMENTATION]: "Recare_Entlassdokumentation",
  [PATIENT_DOCUMENTS.DISCHARGE]: "Recare_Entlassbestaetigung",
  [PATIENT_DOCUMENTS.TRANSITIONAL_CARE]: "Recare_Uebergangspflege",
} as const;

export function postPatientDocument({
  careseekerName,
  documentType,
  encodedPdf,
  externalId,
  on_premise_authorization_token,
  on_premise_domain_ssl,
  targetHTML,
  trackEvent,
  userId,
}: {
  careseekerName: string;
  documentType: PatientDocumentType;
  encodedPdf: string;
  externalId: string;
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string;
  targetHTML: string;
  trackEvent: TrackEventFn;
  userId: string;
}): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const trackImport = (status: number) => {
      trackEvent({
        endpoint: on_premise_domain_ssl,
        name: TRACK_EVENTS.HL7_FILE_EXPORT,
        status,
      });
    };

    let content = "";
    try {
      content = btoa(targetHTML); // Base64 encode the HTML string
    } catch (error) {
      trackImport(-2);
      reject("Encoding failed");
      return;
    }

    const documentName = `${documentNames[documentType]}_${userId}`;

    onPremHttpRequest({
      on_premise_domain_ssl,
      path: `patient/${externalId}/document`,
      method: "POST",
      on_premise_authorization_token,
      body: JSON.stringify({
        content,
        content_pdf: encodedPdf,
        document_type: documentType,
        document_name: documentName,
        document_description: `${documentDescriptions[documentType]}`,
        patient_id: userId,
        careseeker_name: careseekerName
          .replaceAll("ä", "ae")
          .replaceAll("ö", "oe")
          .replaceAll("ü", "ue")
          .replaceAll(" ", "_"),
      }),
    })
      .then((response) => {
        trackImport(response.status);
        resolve(documentName);
      })
      .catch((err) => {
        trackImport(-1);
        console.error(`Isik transfer type ${documentType} error`, err);
        reject(err);
      });
  });
}
